import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimSoftwareConsumablesService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_software_consumables"), request.get);
  },
  get(claimSoftwareConsumable) {
    return fetch("/api/claims/".concat(claimSoftwareConsumable.claim_id, "/claim_software_consumables/").concat(claimSoftwareConsumable.id), request.get);
  },
  delete(claimSoftwareConsumable) {
    return fetch("/api/claims/".concat(claimSoftwareConsumable.claim_id, "/claim_software_consumables/").concat(claimSoftwareConsumable.id), request.delete);
  },
  update(claimSoftwareConsumable) {
    return fetch("/api/claims/".concat(claimSoftwareConsumable.claim_id, "/claim_software_consumables/").concat(claimSoftwareConsumable.id), {
      ...request.put,
      body: JSON.stringify({
        claim_software_consumable: claimSoftwareConsumable
      })
    });
  },
  create(claimSoftwareConsumable) {
    return fetch("/api/claims/".concat(claimSoftwareConsumable.claim_id, "/claim_software_consumables"), {
      ...request.post,
      body: JSON.stringify({
        claim_software_consumable: claimSoftwareConsumable
      })
    });
  }
};
export default claimSoftwareConsumablesService;