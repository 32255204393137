import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import rndCollaborateContactsService from '@rd-web-markets/uk/dist/services/claim_groups/rnd_collaborate_contacts.service';
import ContactsList from './ContactsList'
import ContactForm from './ContactForm'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function ContactDetails({ claimGroup, claimGroupContacts, changeContactClaimGroup, removeContactAttribute, size = 'sm', isUnderReview, pageAccess }) {
  const { report_id: reportId} = useParams();
  const [contacts, setContacts] = React.useState([])
  const [currentContact, setCurrentContact] = React.useState(null)

  const dispatch = useDispatch();

  const setEditContact = (contact) => {
    setCurrentContact(contact)
  }

  const editContactClaimGroup = (contact) => {
    changeContactClaimGroup(contact)
    setCurrentContact(null)
  }

  const getContacts = async () => {
    try {
      const contacts = await rndCollaborateContactsService.all(reportId)
      setContacts(contacts)
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  const createContact = async (newContact) => {
    try {
      await rndCollaborateContactsService.create({ newContact, reportId })
      getContacts()
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  const removeContact = async (contactId) => {
    try {
      await rndCollaborateContactsService.delete({ contactId, reportId })
      setContacts(contacts.filter(contact => contact.id !== contactId))
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  const updateContact = async (contact) => {
    try {
      await rndCollaborateContactsService.update({ contact, reportId })
      await getContacts()
      setCurrentContact(null)
    } catch (error) {
      dispatch(handleError(error))
    }
  }

  useEffect(() => {
    !claimGroupContacts && getContacts()
  }, [reportId])

  return (
    <>
      <ContactsList
        pageAccess={pageAccess}
        contacts={claimGroupContacts || contacts}
        setEditContact={setEditContact}
        removeContact={removeContact}
        removeContactAttribute={removeContactAttribute}
        isUnderReview={isUnderReview}
      />
      <ContactForm
        pageAccess={pageAccess}
        createContact={claimGroupContacts ? changeContactClaimGroup : createContact}
        updateContact={claimGroupContacts ? editContactClaimGroup : updateContact}
        currentContact={currentContact}
        size={size}
        isUnderReview={isUnderReview}
      />
    </>
  )
}
