import ImmutableUnderReviewFormControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ImmutableUnderReviewFormControl';
import React, { useEffect, useState, useRef } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';

const defaultContact = {
  name: '',
  email: '',
  phone_number: '',
  qualifications: '',
  experience: '',
  notes: ''
}

const ContactForm = ({ createContact, updateContact, currentContact, size = 'sm', isUnderReview, pageAccess }) => {
  const [selectedContact, setSelectedContact] = useState(defaultContact);
  const formRef = useRef(null);

  const handleChange = e => {
    const value = e.target.value;
    setSelectedContact({
      ...selectedContact,
      [e.target.name]: value,
    });
  }

  const submit = async () => {
    if (!formRef.current.checkValidity()) {
      formRef.current.reportValidity();
      return;
    } else {
      if (currentContact) {
        await updateContact(selectedContact);
      } else {
        await createContact(selectedContact);
      }
      setSelectedContact(defaultContact);
    }
  }

  const isDisabled = selectedContact['name'] === '' ||
  selectedContact['email'] === '' ||
  selectedContact['phone_number'] === '' ||
  selectedContact['qualifications'] === '' ||
  selectedContact['experience'] === ''

  const setValidationMasasgeForPhone = (e) => {
    e.target.setCustomValidity('');
    if (!e.target.validity.valid) {
        e.target.setCustomValidity('Enter a valid phone number');
    }
  }

  useEffect(() => {
    if (currentContact) {
      setSelectedContact(currentContact);
    }
  }, [currentContact])

  return (
    <Form ref={formRef}>
      <Row>
        <Col className="font-weight-bold" md={size === 'lg' ? 4 : 2}>
          Name:
        </Col>
        <Col className="font-weight-bold" md={size === 'lg' ? 4 : 2}>
          Qualifications of technical personnel e.g.
          degrees, Masters, PhDs, Chartership etc.
        </Col>
        <Col className="font-weight-bold" md={size === 'lg' ? 4 : 2}>
          Relevant еxperience of key technical personnel e.g.
          years in relevant roles during their career:
        </Col>
        <Col className="font-weight-bold" md={size === 'lg' ? 4 : 2}>
          Email:
        </Col>
        <Col className="font-weight-bold" md={size === 'lg' ? 4 : 2}>
          Telephone:
        </Col>
        <Col md={size === 'lg' ? 4 : 2}>
        </Col>
      </Row>
      <Form.Group as={Row}>
        <Col md={size === 'lg' ? 4 : 2}>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            name="name"
            value={selectedContact.name}
            size="md"
            type="text"
            disabled={isUnderReview || !pageAccess.canEditProjectDetails}
          ></ImmutableUnderReviewFormControl>
        </Col>
        <Col md={size === 'lg' ? 4 : 2}>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            name="qualifications"
            value={selectedContact.qualifications}
            size="md"
            type="text"
            disabled={isUnderReview || !pageAccess.canEditProjectDetails}
          ></ImmutableUnderReviewFormControl>
        </Col>
        <Col md={size === 'lg' ? 4 : 2}>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            name="experience"
            value={selectedContact.experience}
            size="md"
            type="text"
            disabled={isUnderReview || !pageAccess.canEditProjectDetails}
          ></ImmutableUnderReviewFormControl>
        </Col>
        <Col md={size === 'lg' ? 4 : 2}>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            type="email"
            as="input"
            name="email"
            value={selectedContact.email}
            size="md"
            disabled={isUnderReview || !pageAccess.canEditProjectDetails}
          ></ImmutableUnderReviewFormControl>
        </Col>
        <Col md={size === 'lg' ? 4 : 2}>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            type="text" 
            pattern="[-+]?\d*"
            onInvalid={setValidationMasasgeForPhone}
            onInput={(e) => e.target.setCustomValidity('')}
            name="phone_number"
            value={selectedContact.phone_number}
            size="md"
            disabled={isUnderReview || !pageAccess.canEditProjectDetails}
          ></ImmutableUnderReviewFormControl>
        </Col>
        <Col md={10}>
          <Form.Label>Notes</Form.Label>
          <ImmutableUnderReviewFormControl
            onChange={handleChange}
            type="text"
            name="notes"
            value={selectedContact.notes}
            size="md"
            as='textarea'
            disabled={isUnderReview || !pageAccess.canEditProjectDetails}
          >
          </ImmutableUnderReviewFormControl>
        </Col>
        <Col md={size === 'lg' ? 2 : 1}>
          <Button
            disabled={isDisabled || isUnderReview || !pageAccess.canEditProjectDetails}
            variant="link"
            onClick={submit}
            >
            <span className="material-icons md-18">add</span> {currentContact ? 'Save' : 'Add'}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  )
}

export default ContactForm
