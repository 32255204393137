export const fields_of_science_and_technology = [{
  value: 'mathematics'
}, {
  value: 'computer_and_information_sciences'
}, {
  value: 'physical_sciences'
}, {
  value: 'chemical_sciences'
}, {
  value: 'earth_and_related_environmental_sciences'
}, {
  value: 'biological_sciences'
}, {
  value: 'other_natural_sciences'
}, {
  value: 'civil_engineering'
}, {
  value: 'electrical_engineering'
}, {
  value: 'mechanical_engineering'
}, {
  value: 'chemical_engineering'
}, {
  value: 'materials_engineering'
}, {
  value: 'medical_engineering'
}, {
  value: 'environmental_engineering'
}, {
  value: 'environmental_biotechnology'
}, {
  value: 'industrial_biotechnology'
}, {
  value: 'nano_technology'
}, {
  value: 'other_engineering'
}, {
  value: 'basic_medicine'
}, {
  value: 'clinical_medicine'
}, {
  value: 'health_sciences'
}, {
  value: 'other_medical_sciences'
}, {
  value: 'agriculture_forestry_fisheries_allied_sciences'
}, {
  value: 'veterinary_medicine'
}, {
  value: 'agriculture_forestry_fisheries'
}, {
  value: 'animal_and_dairy_science'
}, {
  value: 'veterinary_science'
}, {
  value: 'agricultural_biotechnology'
}, {
  value: 'other_agricultural_sciences'
}, {
  value: 'psychology'
}, {
  value: 'economics'
}, {
  value: 'educational_sciences'
}, {
  value: 'other_social_sciences'
}, {
  value: 'economics_and_business'
}, {
  value: 'sociology'
}, {
  value: 'law'
}, {
  value: 'political_science'
}, {
  value: 'social_and_economic_geography'
}, {
  value: 'media_and_communications'
}, {
  value: 'history'
}, {
  value: 'languages_and_literature'
}, {
  value: 'other_humanities'
}, {
  value: 'history_and_archaelogy'
}, {
  value: 'philosophy_ethics_religion'
}, {
  value: 'art'
}];