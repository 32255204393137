import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { api } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import claimSubcontractorsService from './claim_subcontractors.service';
import claimExternalWorkersService from './claim_external_workers.service';
import claimConsumablesService from './claim_consumables.service';
import cloudComputingsService from './cloud_computings.service';
import dataLicencesCostsService from './data_licences_costs.service';
import claimSoftwareConsumablesService from '../claim_software_consumables.service';
import claimEmployeesService from './claim_employees.service';

// only the services that are actually used in the tables
const servicesPerCostFragmentType = {
  'UkSection::ClaimSubcontractor': claimSubcontractorsService,
  'UkSection::ClaimExternalWorker': claimExternalWorkersService,
  'UkSection::ClaimConsumable': claimConsumablesService,
  'UkSection::CloudComputing': cloudComputingsService,
  'UkSection::DataLicencesCost': dataLicencesCostsService,
  'UkSection::ClaimSoftwareConsumable': claimSoftwareConsumablesService,
  'UkSection::ClaimEmployee': claimEmployeesService
};
const costFragmentsService = {
  useGetAll: (claimId, costFragmentType) => {
    const [costFragments, setCostFragments] = useState(null);
    const fetchAll = useErrorHandling(useCallback(async (claimId, costFragmentType) => {
      const response = await costFragmentsService.all(claimId, costFragmentType);
      setCostFragments(response);
    }, []));
    useEffect(() => {
      if (claimId) {
        fetchAll(claimId, costFragmentType);
      }
    }, [claimId, costFragmentType, fetchAll]);
    return [costFragments, setCostFragments, fetchAll];
  },
  useUpdate: (costFragments, setCostFragments) => {
    return useErrorHandling(useCallback(async costFragmentId => {
      const index = costFragments.findIndex(cf => cf.id === costFragmentId);
      const updatedCostFragment = await costFragmentsService.update(costFragments[index]);
      costFragments[index] = updatedCostFragment;
      setCostFragments(...costFragments);
      return updatedCostFragment;
    }, [costFragments, setCostFragments]));
  },
  useUpdateFragmentable: (costFragments, setCostFragments) => {
    return useErrorHandling(useCallback(async costFragmentId => {
      const costFragment = costFragments.find(cf => cf.id === costFragmentId);
      const updateData = {
        id: costFragment.fragmentable_id,
        claim_id: costFragment.claim_id,
        ...costFragment.fragmentable
      };
      const changedFragmentable = await servicesPerCostFragmentType[costFragment.fragmentable_type].update(updateData);
      costFragment.fragmentable = {
        ...changedFragmentable
      };
      setCostFragments([...costFragments]);
    }, [costFragments, setCostFragments]));
  },
  useCreate: (costFragments, setCostFragments) => {
    return useErrorHandling(useCallback(async costFragmentId => {
      const index = costFragments.findIndex(cf => cf.id === costFragmentId);
      const costFragment = {
        ...costFragments[index]
      };
      const fragmentable = {
        ...costFragment.fragmentable,
        claim_id: costFragment.claim_id
      };
      delete costFragment.fragmentable;
      const createdCostFragment = await costFragmentsService.create({
        claim_id: costFragment.claim_id,
        cost_fragment: costFragment,
        fragmentable: fragmentable
      });
      costFragments[index] = createdCostFragment;
      setCostFragments([...costFragments]);
      return createdCostFragment;
    }, [costFragments, setCostFragments]));
  },
  useDelete: onDelete => {
    return useErrorHandling(useCallback(async costFragment => {
      await costFragmentsService.delete(costFragment);
      onDelete(costFragment.id);
    }, [onDelete]));
  },
  get: cost_fragment => api.get("/claims/".concat(cost_fragment.claim_id, "/cost_fragments/").concat(cost_fragment.id)),
  update: cost_fragment => api.update("/claims/".concat(cost_fragment.claim_id, "/cost_fragments/").concat(cost_fragment.id), {
    cost_fragment
  }),
  all: (claimId, costFragmentType) => api.get("/claims/".concat(claimId, "/cost_fragments?fragmentable_type=").concat(costFragmentType)),
  delete: cost_fragment => api.delete("/claims/".concat(cost_fragment.claim_id, "/cost_fragments/").concat(cost_fragment.id)),
  create: cost_fragment => api.create("/claims/".concat(cost_fragment.claim_id, "/cost_fragments"), cost_fragment)
};
export default costFragmentsService;