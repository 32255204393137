import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const rndCollaborateContactsService = {
  all(reportId) {
    return fetch("/api/claim_project_reports/".concat(reportId, "/rnd_collaborate_contacts"), request.get);
  },
  delete(body) {
    return fetch("/api/claim_project_reports/".concat(body.reportId, "/rnd_collaborate_contacts/").concat(body.contactId), request.delete);
  },
  update(body) {
    return fetch("/api/claim_project_reports/".concat(body.reportId, "/rnd_collaborate_contacts/").concat(body.contact.id), {
      ...request.put,
      body: JSON.stringify({
        rnd_collaborate_contact: body.contact
      })
    });
  },
  create(body) {
    return fetch("/api/claim_project_reports/".concat(body.reportId, "/rnd_collaborate_contacts"), {
      ...request.post,
      body: JSON.stringify({
        rnd_collaborate_contact: body.newContact
      })
    });
  }
};
export default rndCollaborateContactsService;