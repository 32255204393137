import { createPagesAbilitiesProxy } from '@rd-web-markets/shared/dist/util/userAbilities';
import { useEffect, useState } from 'react';
export { useGetUserCompanyAbilities } from '@rd-web-markets/shared/dist/util/userAbilities';
function getUserClaimGroupAbilities(user, claimGroupId) {
  if (user.account_type !== 'customer') {
    return createPagesAbilitiesProxy({
      pages: {}
    });
  }

  // by default using useParams will return string, so just in case
  claimGroupId = parseInt(claimGroupId);
  if (!user || !claimGroupId) {
    return createPagesAbilitiesProxy({
      pages: {}
    });
  }
  const userClaimGroupAccess = user.user_accesses.find(user_access => user_access.rollable_id === claimGroupId && user_access.rollable_type === 'ClaimGroup');
  const userIsEngagementTeamMember = userClaimGroupAccess.included_in_engagement_team;
  const userClaimGroupRoles = userClaimGroupAccess.roles;
  const rolesWithProjectOverviewAccess = ['technical_contact', 'financial_contact', 'project_manager'];
  const userRoleHasProjectDetailsAccess = !!rolesWithProjectOverviewAccess.filter(roleWithAccess => userClaimGroupRoles.some(userRole => roleWithAccess === userRole)).length;
  const canAccessProjectDetails = userRoleHasProjectDetailsAccess || userIsEngagementTeamMember;
  const abilities = {
    pages: {
      ProjectOverviewPage: {
        canAccessPage: true,
        canAccessProjectDetailsLink: canAccessProjectDetails
      },
      ClaimProjectReportDetailPage: {
        canAccessPage: canAccessProjectDetails,
        canEditProjectDetails: false,
        canAccessProjectReportTab: canAccessProjectDetails,
        canAccessDocumentsTab: canAccessProjectDetails,
        canAccessNotesTab: canAccessProjectDetails,
        canDeleteProjectNotes: false,
        // except if own notes
        canAccessCompetentProfessionalsTab: userIsEngagementTeamMember,
        canAccessSummarySubtab: userIsEngagementTeamMember,
        canAccessTechnicalNarrativeSubtab: userIsEngagementTeamMember,
        canAccessContactsSubTab: canAccessProjectDetails,
        canEditExistingDocumentsOrFolders: false,
        canEditContacts: false
      },
      ClaimGroupOverviewPage: {
        components: {
          engagementTeam: {
            canEditAnyTypeOfUser: false
          }
        }
      },
      ProjectReportQuestionnairesPage: {
        canEditQuestionnaire: !userIsEngagementTeamMember
      }
    }
  };
  const proxy = createPagesAbilitiesProxy(abilities);
  return proxy;
}
export function useGetUserClaimGroupAbilities(user, claimGroupId) {
  // it always initially returns this state, so we need to build it asap
  const [userClaimGroupAbilities, setUserClaimGroupAbilities] = useState(getUserClaimGroupAbilities(user, claimGroupId));
  useEffect(() => {
    const claimGroupAbilities = getUserClaimGroupAbilities(user, claimGroupId);
    setUserClaimGroupAbilities(claimGroupAbilities);
  }, [user, claimGroupId]);
  return userClaimGroupAbilities;
}