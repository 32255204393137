import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const claimSubcontractorsService = {
  all(claimId) {
    return fetch("/api/claims/".concat(claimId, "/claim_subcontractors"), request.get);
  },
  delete(claimSubcontractor) {
    return fetch("/api/claims/".concat(claimSubcontractor.claim_id, "/claim_subcontractors/").concat(claimSubcontractor.id), request.delete);
  },
  update(claimSubcontractor) {
    return fetch("/api/claims/".concat(claimSubcontractor.claim_id, "/claim_subcontractors/").concat(claimSubcontractor.id), {
      ...request.put,
      body: JSON.stringify({
        claim_subcontractor: claimSubcontractor
      })
    });
  },
  get(claimSubcontractor) {
    return fetch("/api/claims/".concat(claimSubcontractor.claim_id, "/claim_subcontractors/").concat(claimSubcontractor.id), request.get);
  },
  create(claimSubcontractor) {
    return fetch("/api/claims/".concat(claimSubcontractor.claim_id, "/claim_subcontractors"), {
      ...request.post,
      body: JSON.stringify({
        claim_subcontractor: claimSubcontractor
      })
    });
  }
};
export default claimSubcontractorsService;