import React from 'react';
import { Col, Row, Button, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ContactsList = ({ 
  contacts = [], 
  removeContact, 
  removeContactAttribute,
  setEditContact,
  isUnderReview,
  pageAccess
}) => {
  const { t } = useTranslation();

  return (
    <Row className='mb-5'>
      <Col md={12}>
        <h4>Contact details to collaborate on R&D technical narrative:</h4>
      </Col>
      {contacts.length > 0 && <table className="table">
      <thead>
        <tr>
          <th>{t('name')}</th>
          <th>{t('qualifications')}</th>
          <th>{t('experience')}</th>
          <th>{t('email')}</th>
          <th>{t('tel')}</th>
          <th>{t('exclude_from_rnd_report')}</th>
          <th>{t('notes')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact) => {
          return (
            <tr key={contact.id}>
              <td>{contact.name}</td>
              <td>{contact.qualifications}</td>
              <td>{contact.experience}</td>
              <td>{contact.email}</td>
              <td>{contact.phone_number}</td>
              <td>
                  <Form.Check
                    style={{'marginLeft': '4rem'}}
                    type={'checkbox'}
                    disabled={isUnderReview || !pageAccess.canEditProjectDetails}
                  />
              </td>
              <td>
                {contact.notes}
              </td>
              <td>
                { pageAccess.canEditProjectDetails &&
                  <div className='d-flex justify-content-center'>
                    <Button disabled={isUnderReview} onClick={(e) => setEditContact(contact)} variant="primary" className='text-white ml-4'><span className="material-icons md-18">edit</span></Button>
                    <Button disabled={isUnderReview} onClick={(e) => removeContactAttribute ? removeContactAttribute(contact.name) : removeContact(contact.id)} variant="danger" className='text-white ml-1'><span className="material-icons md-18">delete</span></Button>
                  </div>   
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>}
    </Row>
  );
}

export default ContactsList