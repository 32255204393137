export const reportTemplateInitial = {
  rnd: {
    id: 0,
    name: '',
    report_template_categories: [{
      title: 'Executive Summary',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Ayming Overview',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'The Ayming UK Methodology',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Competent Proffesionals',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Financial Analysis',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }]
  },
  aif: {
    id: 0,
    name: '',
    report_template_categories: [{
      title: 'AIF',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }]
  }
};