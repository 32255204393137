import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const themesService = {
  create(companyId, theme) {
    return fetch("/api/companies/".concat(companyId, "/themes"), {
      ...request.post,
      body: JSON.stringify({
        theme
      })
    });
  }
};
export default themesService;